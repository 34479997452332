<template lang="pug">
  .company-list
    TopBar(
      @add-new-company="addNewCompany"
    )
    .table-wrapper
      VueGoodTable.companies-table(
        :rows="companies"
        :columns="columns"
        :sort-options="{ initialSortBy: sorting }"
        @on-sort-change="handleSorting"
      )
        template(v-slot:table-column="props")
          span(:title="props.column.label") {{ $t(props.column.label) }}
        template(v-slot:table-row="{ column, row, formattedRow }")
          .organization-name-field(v-if="column.field === 'name'")
            | {{ row.name }}
          .ota-names-field(v-else-if="column.field === 'ota'")
            | {{ companyOtaNames[row.id] }}
          .expandable-field(v-else-if="column.field === 'organization_names'")
            AppTextExpandable(
              :key="row.id"
              :text="row.organization_names"
            )
          .expandable-field(v-else-if="column.field === 'memo'")
            AppTextExpandable(
              :key="row.id"
              :text="row.memo"
            )
          .table-actions(v-else-if="column.field === 'actions'")
            AppIconButton.action-button(
              icon="edit"
              @click="selectCompany(row.id)"
            )
          span(
            v-else
            :title="formattedRow[column.field]"
          ) {{ formattedRow[column.field] }}
</template>

<script>
  // components
  import { VueGoodTable } from "vue-good-table"
  import Form from "./Form"

  // store modules
  import organizationsModule from "@/config/store/maestro/organizations"
  import companiesModule from "@/config/store/maestro/markups/companies"

  // mixins
  import withModal from "@/mixins/withModal"
  import withStoreModule from "@/mixins/withStoreModule"

  // misc
  import { reduce, map, isEqual } from "lodash-es"
  import { mapGetters } from "vuex"
  import "vue-good-table/dist/vue-good-table.css"
  import { columns } from "./companiesTableConfig"

  const companiesMixin = withStoreModule(companiesModule, {
    name: "maestroCompanies",
    readers: {
      companies: "items",
      sorting: "sorting"
    },
    actions: {
      fetchCompanies: "FETCH_ITEMS"
    },
    mutations: {
      setSorting: "SET_SORTING"
    }
  })

  const organizationsMixin = withStoreModule(organizationsModule, {
    name: "organizations",
    readers: { organizations: "items" },
    actions: { fetchOrganizations: "FETCH_ITEMS" }
  })

  export default {
    components: {
      VueGoodTable,
      TopBar: () => import("./TopBar"),
      AppTextExpandable: () => import("@/components/elements/AppTextExpandable"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [withModal, companiesMixin, organizationsMixin],

    mounted() {
      this.$store.dispatch("FETCH_OTA_LIST")
      this.fetchCompanies()
      this.fetchOrganizations({ pagination: { _disabled: true } })
    },

    computed: {
      columns,

      ...mapGetters(["translatedOtaNamesMapping"]),

      companyOtaNames() {
        return reduce(
          this.companies,
          (otaNames, { id, ota_ids }) => {
            otaNames[id] = map(ota_ids, id => this.translatedOtaNamesMapping[id]).join(", ")
            return otaNames
          },
          {}
        )
      }
    },

    methods: {
      addNewCompany() {
        this.selectCompany(null, this.$t("ota_management.company.form.create"))
      },

      selectCompany(companyId, title = this.$t("ota_management.company.form.edit")) {
        this.$openModal({
          component: Form,
          title,
          size: "large",
          useConfirm: true,
          props: {
            companyId: companyId,
            organizations: this.organizations,
            disabledOrganizationIds: this.disabledOrganizationIds(companyId)
          }
        })
      },

      disabledOrganizationIds(companyId) {
        return reduce(
          this.companies,
          (organizationIds, { id, organization_ids }) => {
            if (id !== companyId) {
              organizationIds.push(...organization_ids)
            }
            return organizationIds
          },
          []
        )
      },

      handleSorting([sortingData]) {
        if (isEqual(sortingData, this.sorting)) return

        this.setSorting(sortingData)
        this.fetchCompanies()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  .company-list
    .table-wrapper
      position: relative

    .companies-table
      +listing-container(-30px)

    ::v-deep
      .vgt-table
        border: none

        .expandable-field
          max-width: 150px
        .id-field
          min-width: 40px
        .active-field
          min-width: 85px
        .vgt-right-align
          // overwrite text align for date fields
          text-align: left

        th
          background: $th-background-color
          border-bottom: 1px solid $default-purple-light-line
          border-left: 1px solid $border-element-color
          border-right: 1px solid $border-element-color
          border-top: none
          color: $default-purple
          font-size: 0.8rem
          font-weight: 700
          padding: 17px 7px

          &:first-child
            border-left: 1px solid $default-purple-light-line

          &:last-child
            border-right: 1px solid $default-purple-light-line

        td
          border-bottom: 1px solid $default-purple-light-line
          border-left: 1px solid $border-element-color
          border-right: 1px solid $border-element-color
          border-top: 1px solid $default-purple-light-line
          color: $td-color
          font-size: 0.8rem
          padding: 11px 7px 13px

          &:first-child
            border-left: 1px solid $default-purple-light-line

          &:last-child
            border-right: 1px solid $default-purple-light-line

          .organization-name-field
            white-space: nowrap

          @media screen and (max-width: 1300px)
            .ota-names-field
              max-width: 200px
          &.actions-field
            width: fit-content
            white-space: nowrap

          .table-actions
            text-align: center

            .app-icon
              fill: $default-purple
              height: 18px
              width: 18px

            .action-button
              +icon-button($default-purple)
</style>
